import { gql } from '@apollo/client';

export const CREATE_CREDIT_LINE_EVALUATION_REQUESTS = gql`
  mutation createCreditLineEvaluationRequests(
    $masterEntity: String!
  ) {
    createCreditLineEvaluationRequests(
      masterEntity: $masterEntity
    ) {
      creditLineEvaluationRequests {
        id
      }
    }
  }
`;

export const REJECT_PRE_OFFER_EVALUATION_REQUESTS = gql`
  mutation RejectPreOfferEvaluationRequests(
    $resolution: RejectPreOfferEvaluationRequestResolutionInputType
  ) {
    rejectPreOfferEvaluationRequests(resolution: $resolution) {
      preOfferEvaluationRequests {
        id
      }
    }
  }
`;

export const ACCEPT_PRE_OFFER_EVALUATION_REQUESTS = gql`
  mutation AcceptPreOfferEvaluationRequests(
    $resolution: AcceptPreOfferEvaluationRequestResolutionInputType
  ) {
    acceptPreOfferEvaluationRequests(resolution: $resolution) {
      preOfferEvaluationRequests {
        id
        status
      }
    }
  }
`;

export const RESOLVE_CREDIT_LINE_EVALUATION_REQUESTS = gql`
  mutation resolveCreditLineEvaluationRequests(
    $resolution: CreditLineEvaluationResolutionInputType!
  ) {
    resolveCreditLineEvaluationRequests(
      resolution: $resolution
    ) {
      creditLineEvaluationRequest {
        id
        status
      }
    }
  }
`;

export const SEND_PREOFFER_EVALUATION_REQUEST_TO_PENDING_DOCUMENTS = gql`
  mutation sendPreofferEvaluationRequestToPendingDocuments(
    $evaluationIds: [Int]!
    ) {
      sendPreofferEvaluationRequestToPendingDocuments(
      evaluationIds: $evaluationIds
      ) {
        evaluationRequests {
          id
          status
        }
      }
    }
`;
